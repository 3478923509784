import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { FaRegEdit } from "react-icons/fa";
import Mask from "../../services/mask";

export default function Participantes() {
  const [listaParticipantes, setListaParticipantes] = useState([]);
  const [filtroColuna, setFiltroColuna] = useState([]);
  const [novoFiltroColuna, setNovoFiltroColuna] = useState([]);
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "nome",
      header: "Nome",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    // {
    //   accessorKey: "codigoLogin",
    //   header: "Código BMW",
    //   muiTableHeadCellProps: {
    //     align: "center",
    //   },
    //   muiTableBodyCellProps: {
    //     align: "center",
    //   },
    // },
    {
      accessorKey: "userName",
      header: "Username",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "email",
      header: "E-mail",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "phoneNumber",
      header: "Telefone",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
  ];

  async function fnExtrairParticipantes() {
    try {
      setLoad(true);

      let response = await ApiServices.apiDownloadXlsxGET(
        `participantes/extrair-base`,
        "cadastroparticipantes"
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnExtrairParticipantesAceite() {
    try {
      setLoad(true);

      let response = await ApiServices.apiDownloadXlsxGET(
        `participantes/extrair-base?aceite=true`,
        "cadastroparticipantes"
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnParticipantes() {
    const parametros = {
      nome: novoFiltroColuna.nome || "",
      userName: novoFiltroColuna.userName || "",
      email: novoFiltroColuna.email || "",
      phoneNumber: novoFiltroColuna.phoneNumber || "",
      codigoBmw: novoFiltroColuna.codigoBmw || "",
      roleName: novoFiltroColuna?.roleDisplayName || "",
      status: novoFiltroColuna.status || "",
    };

    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(`participantes?${queryString}`);

      if (response.statusCode == 200) {
        response.data.forEach((e) => {
          e.userName = Mask.cpf(e.userName);
          e.phoneNumber = Mask.cel(e.phoneNumber);
        });
        setListaParticipantes(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    const newObject = {};
    filtroColuna.forEach((item) => {
      item.id == "role.displayName"
        ? (newObject["roleDisplayName"] = item.value)
        : (newObject[item.id] = item.value);
    });
    setNovoFiltroColuna(newObject);
  }, [filtroColuna]);

  useEffect(() => {
    fnParticipantes();
  }, [novoFiltroColuna]);

  return (
    <>
      <div className="row">
        <span className="tituloPagina">GESTÃO DE PARTICIPANTES</span>

        {/* <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              navigate("/gestao-de-usuarios/participantes/novo");
            }}
          >
            + | Novo participante
          </button>
        </div> */}
        <div className="col-md-3">
          <button
            className="btn btn-secondary my-2"
            onClick={fnExtrairParticipantes}
          >
            Extrair participantes
          </button>
        </div>
        <div className="col-md-4">
          <button
            className="btn btn-secondary my-2"
            onClick={fnExtrairParticipantesAceite}
          >
            Extrair participantes com aceite
          </button>
        </div>
        {/* <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              navigate("/gestao-de-usuarios/participantes/importacao");
            }}
          >
            Importar participantes
          </button>
        </div> */}
        <div className="subTituloPagina">Participantes cadastrados</div>
      </div>
      <Tabela
        colunas={colunas}
        dados={listaParticipantes}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
      />
    </>
  );
}
