import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import Tabela from "../../Components/Tabela";
import moment from "moment";

export default function PerformanceNovoEditar() {
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();
  const [pontosSaldo, setPontosSaldo] = useState(0);
  const [pontosCreditados, setPontosCreditados] = useState(0);
  const [filtroColuna, setFiltroColuna] = useState([]);
  const [performance, setPerformance] = useState({
    nome: "",
    cpf: "",
    cargo: "",
    concessionaria: "",
    pesquisar: "",
  });
  const [listarVendas, setListarVendas] = useState([]);
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "contrato",
      header: "Contrato",
      muiTableHeadCellProps: { align: "left" },
      muiTableBodyCellProps: { align: "left" },
    },
    {
      accessorKey: "data",
      header: "Data",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "chassi",
      header: "Chassi",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "marca",
      header: "Marca",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "valor",
      header: "Valor",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },

    {
      accessorKey: "taxaRegular",
      header: "Taxa regular",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },

    {
      accessorKey: "taxaPromocional",
      header: "Taxa promocional",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },

    {
      accessorKey: "select",
      header: "Select",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },

    {
      accessorKey: "ppi",
      header: "PPI",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },

    {
      accessorKey: "bsI_MSI",
      header: "BSI/MSI",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },

    {
      accessorKey: "garantiaEstendida",
      header: "Garantia estendida",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },

    {
      accessorKey: "acessorios",
      header: "Acessorios",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },

    {
      accessorKey: "seguro",
      header: "Seguro",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },

    {
      accessorKey: "blindagem",
      header: "Blindagem",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },

    {
      accessorKey: "wallBox",
      header: "WallBox",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "retencao",
      header: "Retencao",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "campanhas",
      header: "Campanhas",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "total",
      header: "Total",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
  ];
  async function fnBuscarParticipante() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(
        `performance/detalhe?VendedorId=` + params.id
      );

      setPerformance((prev) => ({
        ...prev,
        nome: response.data.nome,
        cpf: response.data.cpf,
        cargo: response.data.cargo,
        dealerGroup: response.data.dealerGroup,
        codigoBMW: response.data.codigoBMW,
        pontosGanhos: response.data.pontosGanhos,
        concessionaria:
          response.data.dealerCode + " - " + response.data.nomeFantasia,
      }));
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnListarVendas() {
    try {
      setLoad(true);
      const parametros = {
        q: performance.pesquisar || "",
        VendedorId: params.id,
      };
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(
        `performance/detalhe/historico?${queryString}`
      );

      setListarVendas(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListarVendas();
  }, [performance.pesquisar]);

  useEffect(() => {
    fnBuscarParticipante();
  }, []);
  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "PERFORMANCE" : "PERFORMANCE"}
      </div>
      <div className="subTituloPagina">Performance do participante</div>

      <div className="row">
        <div className="col-md-4 mt-3">
          <form autoComplete="off">
            <label>Pontos ganhos (total)</label>
            <input
              autoComplete="off"
              className="inputPrimary"
              disabled
              type="text"
              value={performance?.pontosGanhos?.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              })}
              onChange={() => {}}
            />
          </form>
        </div>
      </div>

      <div className="subTituloPagina">Dados do participante</div>
      <div className="row">
        {/* NOME */}
        <div className="col-md-4 mt-3">
          <form autoComplete="off">
            <label>Nome</label>
            <input
              autoComplete="off"
              className="inputPrimary"
              disabled
              type="text"
              value={performance.nome}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  nome: e.target.value,
                }));
              }}
            />
          </form>
        </div>
        {/* CPF USERNAME */}
        <div className="col-md-4 mt-3">
          <form autoComplete="off">
            <label>CPF (Username)</label>
            <input
              autoComplete="off"
              className="inputPrimary"
              disabled
              type="text"
              placeholder="000.000.000-00"
              value={Mask.cpf(performance.cpf)}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  cpf: Mask.number(e.target.value),
                }));
              }}
            />
          </form>
        </div>
        {/* CODIGO BMW */}
        <div className="col-md-4 mt-3">
          <form autoComplete="off">
            <label>Código BMW</label>
            <input
              autoComplete="off"
              className="inputPrimary"
              disabled
              type="text"
              placeholder="000.000.000-00"
              value={performance.codigoBMW}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  codigoBMW: Mask.number(e.target.value),
                }));
              }}
            />
          </form>
        </div>
      </div>
      <div className="row">
        {/* DEALER */}
        <div className="col-md-4 mt-3">
          <form autoComplete="off">
            <label>Dealer</label>
            <input
              autoComplete="off"
              className="inputPrimary"
              type="text"
              disabled
              value={performance.concessionaria}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  concessionaria: e.target.value,
                }));
              }}
            />
          </form>
        </div>
        {/* GRUPO DEALER */}
        <div className="col-md-4 mt-3">
          <form autoComplete="off">
            <label>Dealer Group</label>
            <input
              autoComplete="off"
              className="inputPrimary"
              type="text"
              disabled
              value={performance.dealerGroup}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  dealerGroup: e.target.value,
                }));
              }}
            />
          </form>
        </div>
        {/* CARGO */}
        <div className="col-md-4 mt-3">
          <form autoComplete="off">
            <label>Cargo</label>
            <input
              autoComplete="off"
              className="inputPrimary"
              disabled
              type="text"
              value={performance.cargo}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  cargo: e.target.value,
                }));
              }}
            />
          </form>
        </div>
      </div>

      <div className="subTituloPagina">Histórico de Vendas</div>
      <div>Pontos ganhos</div>
      <Tabela
        colunas={colunas}
        dados={listarVendas || []}
        paginacaoPelaApi={false}
        setPesquisar={(e) => {
          setPerformance((prev) => ({
            ...prev,
            pesquisar: e,
          }));
        }}
        totalLinhas={listarVendas?.length}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        filtroColuna={filtroColuna}
        buscaColunas={false}
        buscaGlobal={false}
        setFiltroColuna={setFiltroColuna}
      />
      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-secondary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
      </div>
    </div>
  );
}
