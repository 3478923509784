import React, { useEffect, useState } from "react";
import "./index.css";
import storage from "../../services/storage";
import { useNavigate } from "react-router";

export default function Logout() {
  const navigate = useNavigate();
  storage.del("USUARIO");
  useEffect(() => {
    navigate("/login");
  }, []);
  return <>Deslogando...</>;
}
