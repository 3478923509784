import React, { useEffect, useState } from "react";

import dev from "../../assets/images/png/dev.png";
import todasLogo from "../../assets/images/png/todasLogo.png";
import textoAdminLogin from "../../assets/images/png/textoAdminLogin.png";
import duasLogoVertical from "../../assets/images/png/duasLogoVertical.png";
// import LogoMenu from "../../assets/images/pngs/logDev.png";
import { BiUser } from "react-icons/bi";
import { MdAppRegistration, MdOutlineQuiz } from "react-icons/md";
import { FaMagnifyingGlass } from "react-icons/fa6";
import {
  AiOutlineBook,
  AiOutlineSetting,
  AiOutlineAudit,
} from "react-icons/ai";
import { CgPerformance } from "react-icons/cg";
import { TbFileInvoice } from "react-icons/tb";
import { BsGraphUp, BsFillCollectionFill, BsChatLeft } from "react-icons/bs";
import { ImExit } from "react-icons/im";
import { FcMenu } from "react-icons/fc";
import { FaRegNewspaper } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import "./index.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { FaQuestionCircle } from "react-icons/fa";
import { BiSolidReport } from "react-icons/bi";

export default function MenuLateral() {
  const [menuAbrir, setMenuAbrir] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [larguraTela, setLarguraTela] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setLarguraTela(window.innerWidth);
      setMenuAbrir(window.innerWidth < 800 ? true : false);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <Sidebar
        collapsed={menuAbrir}
        style={{ height: "100vh" }}
        collapsedWidth="46px"
      >
        <div
          className="d-flex justify-content-end mt-2"
          onClick={() => {
            setMenuAbrir(!menuAbrir);
          }}
        >
          {menuAbrir ? (
            <MdOutlineKeyboardDoubleArrowRight />
          ) : (
            <MdOutlineKeyboardDoubleArrowLeft />
          )}
        </div>
        {!menuAbrir ? (
          <div className="px-3 mb-5">
            <Link
              to={"/home"}
              className="d-flex flex-column align-items-center justify-content-center"
            >
          
              <br />
              <img
                src={textoAdminLogin}
                alt="logo"
                style={{ width: "80%", minWidth: 40 }}
              />
            </Link>
          </div>
        ) : null}
        <Menu>
          <SubMenu label="Gestão" icon={<BiUser />}>
            <MenuItem
              onClick={() => {
                navigate("/gestao-de-usuarios/administradores");
              }}
            >
              Administradores
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/gestao-de-usuarios/participantes");
              }}
            >
              Participantes
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                navigate("/gestao-de-usuarios/concessionarias");
              }}
            >
              Concessionárias
            </MenuItem> */}
            {/* <MenuItem
              onClick={() => {
                navigate("/gestao-de-usuarios/veiculos");
              }}
            >
              Veículos
            </MenuItem> */}
          </SubMenu>

          <SubMenu label="Campanhas" icon={<MdAppRegistration />}>
            {/* <MenuItem
              onClick={() => {
                navigate("/campanhas/gestao-de-campanhas");
              }}
            >
              Gestão de Campanhas
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                navigate("/campanhas/importacao");
              }}
            >
              Importação
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                navigate("/campanhas/apuracao");
              }}
            >
              Apuração
            </MenuItem> */}
          </SubMenu>

          <SubMenu label="Acompanhamento" icon={<FaMagnifyingGlass />}>
            {/* <MenuItem
              onClick={() => {
                navigate("/acompanhamento/performance");
              }}
            >
              Performance
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                navigate("/acompanhamento/ranking");
              }}
            >
              Ranking
            </MenuItem>
          </SubMenu>

          <SubMenu label="Comunicação" icon={<FaRegNewspaper />}>
            {/* <MenuItem
              onClick={() => {
                navigate("/comunicacao/noticia");
              }}
            >
              Notícias
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                navigate("/comunicacao/banner");
              }}
            >
              Banner
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                navigate("/comunicacao/como-funciona");
              }}
            >
              Como funciona
            </MenuItem> */}
          </SubMenu>

          {/* <SubMenu
            label="Fale Conosco"
            icon={<BsChatLeft />}
          >
            <MenuItem
              onClick={() => {
                navigate("/fale-conosco/chamado");
              }}
            >
              Chamado
            </MenuItem>
          </SubMenu> */}

          <MenuItem
            icon={<FaQuestionCircle />}
            onClick={() => {
              navigate("/faq");
            }}
          >
            FAQ
          </MenuItem>

          {/* <MenuItem
            icon={<BiSolidReport />}
            onClick={() => {
              navigate("/relatorios");
            }}
          >
            Relatórios
          </MenuItem> */}

          <MenuItem
            onClick={() => {
              navigate("/logout");
            }}
            icon={<ImExit />}
          >
            Sair
          </MenuItem>
        </Menu>
      </Sidebar>
    </>
  );
}
