export function validaEmail(email) {
  // Expressão regular para validar um endereço de e-mail
  const regexEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

  // Testa se o e-mail fornecido corresponde à expressão regular
  if (regexEmail.test(email)) {
    return true; // É um e-mail válido
  } else {
    return false; // Não é um e-mail válido
  }
}
