import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { Box } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { BsFiletypeXlsx } from "react-icons/bs";
import modeloRelatorio from "../../assets/Doc/Modelo Importação.xlsx";
import moment from "moment";
import globals from "../../globals";

export default function Importacao() {
  const [importacao, setImportacao] = useState({
    campanha: "",
    importacao: "",
    arquivoAnexado: "",
  });
  const [listaImportacoes, setListaImportacoes] = useState([]);
  const [arquivoFile, setArquivoFile] = useState(null);
  const [nomeArquivo, setNomeArquivo] = useState(null);
  const [filtroColuna, setFiltroColuna] = useState([]);
  const [novoFiltroColuna, setNovoFiltroColuna] = useState([]);

  const [listaTipoImportacao, setListaTipoImportacao] = useState([
    {
      tipoid: "1",
      nome: "Extrato vendedor",
    },
  ]);
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "data",
      header: "Data / Hora",
      muiTableHeadCellProps: { align: "left" },
      muiTableBodyCellProps: { align: "left" },
      Cell: ({ row }) => <div>{row.original.dataHoraFormatada}</div>,
    },
    {
      accessorKey: "participanteNome",
      header: "Usuário",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
      Cell: ({ row }) => <div>{row.original.usuario}</div>,
    },
    {
      accessorKey: "importacaoTipoNome",
      header: "Tipo",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
      Cell: ({ row }) => <div>{row.original.tipo}</div>,
    },
    {
      accessorKey: "nomeArquivo",
      header: "Arquivo",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
      Cell: ({ row }) => <div>{row.original.arquivo}</div>,
    },
    {
      header: "Total",
      id: "mrt-custom-actions-1",
      accessor: (row) => row, // Pode ser ajustado conforme necessário
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            fnExpurgo(row.original.id, "", "Log vendas de total");
          }}
        >
          {row.original.total}
        </div>
      ),
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      header: "Ok",
      id: "mrt-custom-actions-2",
      accessor: (row) => row, // Pode ser ajustado conforme necessário
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            fnExpurgo(row.original.id, "sucesso", "Log vendas de sucesso");
          }}
        >
          {row.original.ok}
        </div>
      ),
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      header: "Duplicado",
      id: "mrt-custom-actions-3",
      accessor: (row) => row, // Pode ser ajustado conforme necessário
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            fnExpurgo(row.original.id, "duplicado", "Log vendas de duplicados");
          }}
        >
          {row.original.duplicado}
        </div>
      ),
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      header: "Erro",
      id: "mrt-custom-actions-4",
      accessor: (row) => row, // Pode ser ajustado conforme necessário
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            fnExpurgo(row.original.id, "erro", "Log vendas com erro");
          }}
        >
          {row.original.erro}
        </div>
      ),
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      header: "Status",
      id: "mrt-custom-actions-5",
      accessor: (row) => row, // Pode ser ajustado conforme necessário
      Cell: ({ row }) => (
        <div>{row.original.erro > 0 ? "Erro" : "Sucesso"}</div>
      ),
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    // {
    //   accessorKey: "status",
    //   header: "Status",
    //   muiTableHeadCellProps: { align: "center" },
    //   muiTableBodyCellProps: { align: "center" },
    // },
  ];

  async function fnExpurgo(id, status, nomeArquivo) {
    setLoad(true);

    try {
      setLoad(true);
      let response = await ApiServices.apiDownloadXlsxGET(
        "ranking/importacoes/detalhes?id=" + id + "&status=" + status,
        nomeArquivo
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListaImportacoes() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("ranking/importacoes");

      response.data.forEach((element) => {
        element.data = moment(element.data).format("DD/MM/YYYY HH:mm:ss");
      });

      if (response.statusCode == 200) {
        setListaImportacoes(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCarregarArquivo(e) {
    setLoad(true);
    try {
      const file = e.target.files[0];
      const dataForm = new FormData();
      dataForm.append("file", file);
      setArquivoFile(dataForm);
      setNomeArquivo(file.name);
      setLoad(false);
    } catch (err) {
      setLoad(false);
      console.error("Erro ao fazer upload:", err);
    } finally {
    }
  }
  async function fnEnviarArquivo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiUpload(
        `ranking/${globals.REACT_APP_BUSINESS}/importar`,
        arquivoFile
      );

      setImportacao((prev) => ({
        ...prev,
        arquivoAnexado: response.data,
      }));
      document.querySelector(".inputUpload").value = "";
      setNomeArquivo("");
      fnListaImportacoes();

      toast.success("Importação recebida");
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnBaixarModelo() {
    try {
      setLoad(true);
      const response = await fetch(modeloRelatorio);
      const blob = await response.blob();
      const fileName = "Modelo de Relatório de vendas.xlsx";
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    fnListaImportacoes();
  }, []);

  let dadosOrdenados = listaImportacoes.sort(
    (a, b) => new Date(b.dataHora) - new Date(a.dataHora)
  );

  return (
    <>
      <div className="row">
        <span className="tituloPagina">IMPORTAÇÃO</span>
        <div className="row d-flex align-items-end ">
          <div className="col-md-3 mt-3">
            <form autoComplete="off">
              <label>Tipo de importação</label>

              <select
                className="inputPrimary"
                value={importacao.importacao}
                disabled
                onChange={(e) => {
                  setImportacao((prev) => ({
                    ...prev,
                    importacao: e.target.value,
                  }));
                }}
              >
                {/* <option value="">Selecione</option> */}
                {listaTipoImportacao?.map((e, i) => (
                  <option key={i} value={e.tipoid}>
                    {e?.nome}
                  </option>
                ))}
              </select>
            </form>
          </div>

          <div className="col-md-2 mt-3">
            <input
              type="file"
              accept=".xlsx"
              className="inputUpload d-none "
              onChange={fnCarregarArquivo}
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                document.querySelector(".inputUpload").click();
              }}
            >
              Escolher arquivo
            </button>
          </div>
          <div className="col-md-2 mt-3">
            <button
              className="btn btn-secondary "
              disabled={!nomeArquivo}
              onClick={fnEnviarArquivo}
            >
              Importar arquivo
            </button>
          </div>
          <div className="col-md-2 mt-3">
            <button className="btn btn-secondary " onClick={fnBaixarModelo}>
              Baixar modelo
            </button>
          </div>
        </div>
        {nomeArquivo && (
          <div className="d-flex align-items-center  mt-3">
            <BsFiletypeXlsx style={{ fontSize: 30 }} />
            <div className="ms-2">{nomeArquivo}</div>
          </div>
        )}
        <b style={{ marginTop: 30 }}>Orientações</b>
        <p style={{ fontSize: 12 }}>
          Para fazer o upload em massa do seu estoque, siga as orientações
          abaixo:
          <br />
          • Clique em "Baixar Modelo" para fazer o download da planilha de
          importação;
          <br />
          • A planilha deve estar no formato .xlsx;
          <br />
          • O arquivo não deve conter células mescladas;
          <br />
          • A primeira linha deve conter o cabeçalho das colunas;
          <br />
          • Deve haver somente uma aba no arquivo;
          <br />
          • Após a importação, veja o resultado de cada registro clicando nos
          números processados;
          <br />
          • No caso de erro no upload, será gerada uma planilha com o descritivo
          do erro de leitura na última coluna. Favor consultar, ajustar e
          carregar novamente.
          <br />
        </p>
      </div>
      <Tabela
        colunas={colunas}
        dados={dadosOrdenados}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
      />
    </>
  );
}
