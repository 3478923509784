import React from "react";
import { Routes, Route, Navigate } from "react-router-dom"; 
import Cadastro from "../Pages/Cadastro"; 
import Home from "../Pages/Home";
import Login from "../Pages/Login"; 
import storage from "../services/storage";
import Menu from "../Components/Menu";
import Logout from "../Pages/Logout";
import Administradores from "../Pages/Administradores";
import Participantes from "../Pages/Participantes";
import AdministradoresNovoEditar from "../Pages/Administradores/NovoEditar";
import ParticipantesNovoEditar from "../Pages/Participantes/NovoEditar";
import Concessionarias from "../Pages/Concessionarias";
import ConcessionariasNovoEditar from "../Pages/Concessionarias/NovoEditar";
import Campanhas from "../Pages/Campanhas";
import CampanhasNovoEditar from "../Pages/Campanhas/NovoEditar";  
import Noticias from "../Pages/Noticias";
import NoticiasNovoEditar from "../Pages/Noticias/NovoEditar";
import Chamado from "../Pages/Chamado";
import ChamadoNovoEditar from "../Pages/Chamado/NovoEditar";
import PerformanceNovoEditar from "../Pages/Performance/NovoEditar";
import Performance from "../Pages/Performance";
import RecriarSenha from "../Pages/RecriarSenha"; 
import ImportacaoParticipantes from "../Pages/Importacao/ImportacaoParticipantes";
import ImportacaoConcessionarias from "../Pages/Importacao/ImportacaoConcessionarias";
import Apuracao from "../Pages/Apuracao";
import Ranking from "../Pages/Ranking";
import Faq from "../Pages/Faq";
import FaqNovoEditar from "../Pages/Faq/NovoEditar";
import Relatorios from "../Pages/Relatorios";
import ComoFunciona from "../Pages/ComoFunciona";
import ComoFuncionaNovoEditar from "../Pages/ComoFunciona/NovoEditar";
import Banner from "../Pages/Banner";
import BannerNovoEditar from "../Pages/Banner/NovoEditar";
import Veiculos from "../Pages/Veiculos";
import VeiculosNovoEditar from "../Pages/Veiculos/NovoEditar";
import ImportacaoVeiculos from "../Pages/Importacao/ImportacaoVeiculos";
import Importacao from "../Pages/Campanhas/importacao";


const PrivateRoutes = ({ children, redirectTo }) => {
  const autenticado = storage.get("USUARIO")?.token != null;
  // const autenticado = true;
  return autenticado ? (
    <div
      className="d-flex w-100 "
      style={{ height: "100vh", overflow: "hidden" }}
    >
      <div style={{ height: "100vh" }}>
        <Menu />
      </div>

      <div className="w-100" style={{ overflowX: "hidden" }}>
        {children}
      </div>
    </div>
  ) : (
    <Navigate to={redirectTo} />
  );
};


export default function Rotas() {
  return (
    <div className=" ">
      <Routes>
        <Route exact path="/" element={<Navigate to={"/login"} />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/esqueci-senha" element={<Login />} />
        <Route exact path="/primeiro-acesso" element={<Login />} />
        <Route exact path="/faq-deslogado" element={<Login />} /> 
        <Route exact path="/alterar-senha" element={<Login />} /> 

        <Route exact path="/home" element={ <PrivateRoutes redirectTo={"/"}><Home /></PrivateRoutes>}/>

        <Route exact path="/gestao-de-usuarios/administradores" element={ <PrivateRoutes redirectTo={"/"}><Administradores /></PrivateRoutes>}/>
        <Route exact path="/gestao-de-usuarios/administradores/novo" element={ <PrivateRoutes redirectTo={"/"}><AdministradoresNovoEditar /></PrivateRoutes>}/>
        <Route exact path="/gestao-de-usuarios/administradores/editar/:id" element={ <PrivateRoutes redirectTo={"/"}><AdministradoresNovoEditar /></PrivateRoutes>}/>
        
        <Route exact path="/gestao-de-usuarios/participantes" element={ <PrivateRoutes redirectTo={"/"}><Participantes /></PrivateRoutes>}/>
        <Route exact path="/gestao-de-usuarios/participantes/novo" element={ <PrivateRoutes redirectTo={"/"}><ParticipantesNovoEditar /></PrivateRoutes>}/>
        <Route exact path="/gestao-de-usuarios/participantes/editar/:id" element={ <PrivateRoutes redirectTo={"/"}><ParticipantesNovoEditar /></PrivateRoutes>}/>
        <Route exact path="/gestao-de-usuarios/participantes/importacao" element={ <PrivateRoutes redirectTo={"/"}><ImportacaoParticipantes  /></PrivateRoutes>}/>
 
        <Route exact path="/gestao-de-usuarios/concessionarias" element={ <PrivateRoutes redirectTo={"/"}><Concessionarias /></PrivateRoutes>}/>
        <Route exact path="/gestao-de-usuarios/concessionarias/novo" element={ <PrivateRoutes redirectTo={"/"}><ConcessionariasNovoEditar /></PrivateRoutes>}/>
        <Route exact path="/gestao-de-usuarios/concessionarias/editar/:id" element={ <PrivateRoutes redirectTo={"/"}><ConcessionariasNovoEditar /></PrivateRoutes>}/>
        <Route exact path="/gestao-de-usuarios/concessionarias/importacao" element={ <PrivateRoutes redirectTo={"/"}><ImportacaoConcessionarias /></PrivateRoutes>}/>

        <Route exact path="/gestao-de-usuarios/veiculos" element={ <PrivateRoutes redirectTo={"/"}><Veiculos /></PrivateRoutes>}/>
        <Route exact path="/gestao-de-usuarios/veiculos/novo" element={ <PrivateRoutes redirectTo={"/"}><VeiculosNovoEditar /></PrivateRoutes>}/>
        <Route exact path="/gestao-de-usuarios/veiculos/editar/:id" element={ <PrivateRoutes redirectTo={"/"}><VeiculosNovoEditar /></PrivateRoutes>}/>
        <Route exact path="/gestao-de-usuarios/veiculos/importacao" element={ <PrivateRoutes redirectTo={"/"}><ImportacaoVeiculos /></PrivateRoutes>}/>
      
        <Route exact path="/campanhas/gestao-de-campanhas" element={ <PrivateRoutes redirectTo={"/"}><Campanhas /></PrivateRoutes>}/>
        <Route exact path="/campanhas/gestao-de-campanhas/novo" element={ <PrivateRoutes redirectTo={"/"}><CampanhasNovoEditar /></PrivateRoutes>}/>
        <Route exact path="/campanhas/gestao-de-campanhas/editar/:id" element={ <PrivateRoutes redirectTo={"/"}><CampanhasNovoEditar /></PrivateRoutes>}/>
       
        <Route exact path="/faq" element={ <PrivateRoutes redirectTo={"/"}><Faq /></PrivateRoutes>}/>
        <Route exact path="/faq/novo" element={ <PrivateRoutes redirectTo={"/"}><FaqNovoEditar /></PrivateRoutes>}/>
        <Route exact path="/faq/editar/:id" element={ <PrivateRoutes redirectTo={"/"}><FaqNovoEditar /></PrivateRoutes>}/>
      
        <Route exact path="/campanhas/importacao" element={ <PrivateRoutes redirectTo={"/"}><Importacao /></PrivateRoutes>}/>
        {/* <Route exact path="/campanhas/apuracao" element={ <PrivateRoutes redirectTo={"/"}><Apuracao /></PrivateRoutes>}/> */}

        {/* <Route exact path="/acompanhamento/performance" element={ <PrivateRoutes redirectTo={"/"}><Performance /></PrivateRoutes>}/> */}
        {/* <Route exact path="/acompanhamento/ranking" element={ <PrivateRoutes redirectTo={"/"}>< /></PrivateRoutes>}/> */}

        <Route exact path="/acompanhamento/performance" element={ <PrivateRoutes redirectTo={"/"}><Performance/></PrivateRoutes>}/>
        <Route exact path="/acompanhamento/performance/novo" element={ <PrivateRoutes redirectTo={"/"}><PerformanceNovoEditar/></PrivateRoutes>}/>
        <Route exact path="/acompanhamento/performance/editar/:id" element={ <PrivateRoutes redirectTo={"/"}><PerformanceNovoEditar/></PrivateRoutes>}/>
      
        <Route exact path="/acompanhamento/ranking" element={ <PrivateRoutes redirectTo={"/"}><Ranking/></PrivateRoutes>}/>
        <Route exact path="/relatorios" element={ <PrivateRoutes redirectTo={"/"}><Relatorios/></PrivateRoutes>}/>

        {/* <Route exact path="/comunicacao/noticia" element={ <PrivateRoutes redirectTo={"/"}><Noticias/></PrivateRoutes>}/>
        <Route exact path="/comunicacao/noticia/novo" element={ <PrivateRoutes redirectTo={"/"}><NoticiasNovoEditar/></PrivateRoutes>}/>
        <Route exact path="/comunicacao/noticia/editar/:id" element={ <PrivateRoutes redirectTo={"/"}><NoticiasNovoEditar/></PrivateRoutes>}/> */}


        <Route exact path="/comunicacao/banner" element={ <PrivateRoutes redirectTo={"/"}><Banner/></PrivateRoutes>}/>
        <Route exact path="/comunicacao/banner/novo" element={ <PrivateRoutes redirectTo={"/"}><BannerNovoEditar/></PrivateRoutes>}/>
        <Route exact path="/comunicacao/banner/editar/:id" element={ <PrivateRoutes redirectTo={"/"}><BannerNovoEditar/></PrivateRoutes>}/>
      
        <Route exact path="/comunicacao/como-funciona" element={ <PrivateRoutes redirectTo={"/"}><ComoFunciona/></PrivateRoutes>}/>
        <Route exact path="/comunicacao/como-funciona/novo" element={ <PrivateRoutes redirectTo={"/"}><ComoFuncionaNovoEditar/></PrivateRoutes>}/>
        <Route exact path="/comunicacao/como-funciona/editar/:id" element={ <PrivateRoutes redirectTo={"/"}><ComoFuncionaNovoEditar/></PrivateRoutes>}/>
      
        <Route exact path="/fale-conosco/chamado" element={ <PrivateRoutes redirectTo={"/"}><Chamado/></PrivateRoutes>}/>
        <Route exact path="/fale-conosco/chamado/novo" element={ <PrivateRoutes redirectTo={"/"}><ChamadoNovoEditar/></PrivateRoutes>}/>
        <Route exact path="/fale-conosco/chamado/editar/:id" element={ <PrivateRoutes redirectTo={"/"}><ChamadoNovoEditar/></PrivateRoutes>}/>
      

      </Routes>

      {/* {storage.get("POLITICA") !== "true" && <BarraPolitica />} */}
    </div>
  );
}
