import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { Box } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { BsFiletypeXlsx } from "react-icons/bs";
import Mask from "../../services/mask";
export default function Performance() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [listarParticipantes, setListarParticipantes] = useState([]);
  const [arquivoFile, setArquivoFile] = useState(null);
  const [nomeArquivo, setNomeArquivo] = useState(null);
  const [filtroColuna, setFiltroColuna] = useState([]);

  const [performance, setPerformance] = useState({
    pesquisar: "",
    marcaId: "",
    grupoId: "",
    dealerId: "",
    mes: "",
    ano: "",
  });

  const [listarMarca, setListarMarca] = useState([]);
  const [listaGrupoRanking, setListaGrupoRanking] = useState([]);
  const [listaDealer, setListaDealer] = useState([]);
  const [paginacao, setPaginacao] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "nome",
      header: "Nome",
      muiTableHeadCellProps: { align: "left" },
      muiTableBodyCellProps: { align: "left" },
    },
    {
      accessorKey: "codigoBMW",
      header: "Código BMW",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "cpf",
      header: "CPF (userName)",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "email",
      header: "E-mail",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "telefone",
      header: "Telefone",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "cargo",
      header: "Cargo",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "pontosGanhos",
      header: "Pontos ganhos",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      header: "Ações",
      size: 90,
      id: "mrt-custom-actions",
      accessor: (row) => row,
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
     
            navigate("/acompanhamento/performance/editar/" + row.original.id);
          }}
        >
          <FaRegEdit style={{ fontSize: 20, color: "#db3db1" }} />
        </div>
      ),
      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];

  async function fnListarParticipantes() {
    const parametros = {
      q: performance.pesquisar || "",
      page: paginacao.pageIndex || "1",
      pageSize: paginacao.pageSize || "",
      grupoId: performance.grupoId || "",
      dealerId: performance.dealerId || "",
      marcaId: performance.marcaId || "",
    };

    setLoad(true);
    try {
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(`performance?${queryString}`);
      response.data.forEach((element) => {
        element.cpf = Mask.cpf(element.cpf);
        element.pontosGanhos = element.pontosGanhos.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
        });
      });
      if (response.statusCode == 200) {
        setListarParticipantes(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarMarca() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("participantes");

      if (response.statusCode == 200) {
        setListarMarca(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarGrupoRanking() {
    setLoad(true);
    const parametros = {
      MarcaId: performance.marcaId || "",
    };

    try {
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(
        "ranking/grupo/todos?" + queryString
      );

      if (response.statusCode == 200) {
        setListaGrupoRanking(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarDealer() {
    const parametros = {
      MarcaId: performance.marcaId || "",
      GrupoId: performance.grupoId || "",
    };
    setLoad(true);
    try {
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(
        `dealer/pra-performance?${queryString}`
      );

      if (response.statusCode == 200) {
        setListaDealer(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCarregarArquivo(e) {
    setLoad(true);
    try {
      const file = e.target.files[0];
      const dataForm = new FormData();
      dataForm.append("file", file);
      setArquivoFile(dataForm);
      setNomeArquivo(file.name);
      setLoad(false);
    } catch (err) {
      setLoad(false);
      console.error("Erro ao fazer upload:", err);
    } finally {
    }
  }
  async function fnEnviarArquivo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiUpload(
        `upload/campanha`,
        arquivoFile
      );

      setPerformance((prev) => ({
        ...prev,
        arquivoAnexado: response.data,
      }));
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnExtrair() {
    try {
      setLoad(true);
      const parametros = {
        q: performance.pesquisar || "",
        grupoId: performance.grupoId || "",
        dealerId: performance.dealerId || "",
        marcaId: performance.marcaId || "",
      };
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiDownloadXlsxGET(
        `performance/exportar?${queryString}`,
        "relatorio de performace"
      );
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListarMarca();
  }, []);

  useEffect(() => {
    if (performance.marcaId) {
      fnListarGrupoRanking();
    }
  }, [performance.marcaId]);

  useEffect(() => {
    if (performance.marcaId === "") {
      setPerformance((prev) => ({ ...prev, grupoId: "", dealerId: "" }));
      setListaGrupoRanking([]);
      setListaDealer([]);
    }
  }, [performance.marcaId]);

  useEffect(() => {
    if (performance.grupoId && performance.marcaId) {
      fnListarDealer();
    }
  }, [performance.grupoId, performance.marcaId]);

  useEffect(() => {
    if (performance.grupoId === "") {
      setPerformance((prev) => ({ ...prev, dealerId: "" }));
      setListaDealer([]);
    }
  }, [performance.grupoId]);

  useEffect(() => {
    fnListarParticipantes();
    if (performance.marcaId && performance.grupoId && performance.dealerId) {
    }
  }, [
    paginacao.pageSize,
    paginacao.pageIndex,
    performance.pesquisar,
    performance.marcaId,
    performance.grupoId,
    performance.dealerId,
  ]);

  return (
    <>
      <div className="row">
        <span className="tituloPagina">PERFORMANCE</span>

        <div className="row">
          <div className="col-md-2 mt-3">
            <label>Marca</label>
            <select
              className="inputPrimary"
              value={performance.marcaId}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  marcaId: e.target.value,
                }));
              }}
            >
              <option value="">Todos</option>
              {listarMarca.map((e, i) => (
                <option key={i} value={e.marcaId}>
                  {e.descricao}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2 mt-3">
            <label>Grupo Ranking</label>
            <select
              className="inputPrimary"
              value={performance.grupoId}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  grupoId: e.target.value,
                }));
              }}
            >
              <option value="">Todos</option>
              {listaGrupoRanking.map((e, i) => (
                <option key={i} value={e.grupoRankingId}>
                  {e.descricao}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-2 mt-3">
            <label>Dealer</label>
            <select
              className="inputPrimary"
              value={performance.dealerId}
              onChange={(e) => {
                e.persist();
                setPerformance((prev) => ({
                  ...prev,
                  dealerId: e.target.value,
                }));
              }}
            >
              <option value="">Todos</option>
              {listaDealer.map((e, i) => (
                <option key={i} value={e.dealerId}>
                  {e.codigoDealer + " -" + e.nomeFantasia}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2 mt-3">
            <label> </label>
            <button
              className="btn btn-primary "
              onClick={fnListarParticipantes}
            >
              Buscar
            </button>
          </div>
          <div className="col-md-2 mt-3">
            <label> </label>
            <button className="btn btn-secondary " onClick={fnExtrair}>
              Extrair
            </button>
          </div>
        </div>

        <div className="row ">
          <div className="col-md-12 mt-3 ">
            {nomeArquivo && (
              <div className="d-flex align-items-center">
                <BsFiletypeXlsx style={{ fontSize: 30 }} />
                <div className="ms-2">{nomeArquivo}</div>
              </div>
            )}
          </div>
        </div>

        <span className="subTituloPagina mt-5">Participantes</span>
      </div>

      <Tabela
        colunas={colunas}
        dados={listarParticipantes || []}
        paginacaoPelaApi={false}
        buscaGlobal={true}
        setPesquisar={(e) => {
          setPerformance((prev) => ({
            ...prev,
            pesquisar: e,
          }));
        }}
        totalLinhas={listarParticipantes?.length}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
        buscaColunas={false}
      />
    </>
  );
}
