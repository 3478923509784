import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { Box } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { BsFiletypeXlsx } from "react-icons/bs";
export default function Ranking() {
  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [listarRanking, setListarRanking] = useState([]);
  const [arquivoFile, setArquivoFile] = useState(null);
  const [nomeArquivo, setNomeArquivo] = useState(null);
  const [filtroColuna, setFiltroColuna] = useState([]);
  const [ranking, setRanking] = useState({
    pesquisar: "",
    marcaId: "",
    grupoId: "",
    roleId: "",
  });

  const [listarConcessionarias, setListarConcessionarias] = useState([]);
  const [listaMarca, setListaMarca] = useState([]);
  const [listaCargo, setListaCargo] = useState([]);
  const [listaGrupoRanking, setListaGrupoRanking] = useState([]);
  const [paginacao, setPaginacao] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  const colunasVendedor = [
    {
      accessorKey: "nome",
      header: "Nome",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
      accessor: (row) => row,
      Cell: ({ row }) => <div>{row.original.nomeRevendedor}</div>,
    },
    {
      accessorKey: "regiao",
      header: "Região",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "receita",
      header: "Receita",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "posicaoRankingBrasil",
      header: "Posição ranking Brasil",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "requisitoViagem",
      header: "Requisito viagem",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
    {
      accessorKey: "rankingRegional",
      header: "Posição regional",
      muiTableHeadCellProps: { align: "center" },
      muiTableBodyCellProps: { align: "center" },
    },
  ];

  async function fnListarRanking() {
    const parametros = {
      q: ranking.pesquisar || "",
      page: paginacao.pageIndex || "1",
      pageSize: paginacao.pageSize || "",
      MarcaId: ranking.marcaId || "",
      GrupoId: ranking.grupoId || "",
      RoleId: ranking.roleId || "",
    };

    setLoad(true);
    try {
      const queryString = new URLSearchParams(parametros).toString();
      var url = "";
      if (ranking.roleId === "89B97424-23F8-455C-AD38-A9A2D9B1784B") {
        url = `ranking/gerente?${queryString}`;
      }
      if (ranking.roleId === "BD599436-282A-4B76-B419-4D767551DBD6") {
        url = `ranking/vendedor?${queryString}`;
      }
      let response = await ApiServices.apiGet(url);

      if (response.statusCode == 200) {
        setListarRanking(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarMarca() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet("ranking/importacoes/todos");

      if (response.statusCode == 200) {
        setListaMarca(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarGrupoRanking() {
    setLoad(true);
    const parametros = {
      regiao: ranking.marcaId || "",
    };

    try {
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(
        "ranking/importacoes/todos?" + queryString
      );

      if (response.statusCode == 200) {
        response.data.forEach(
          (e, i) => (e.receita = e.receita.toLocaleString("pt-br"))
        );

        setListaGrupoRanking(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  async function fnListarCargos() {
    setLoad(true);
    try {
      let response = await ApiServices.apiGet(
        "identidade/participante/permissoes"
      );

      if (response.statusCode == 200) {
        setListaCargo(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCarregarArquivo(e) {
    setLoad(true);
    try {
      const file = e.target.files[0];
      const dataForm = new FormData();
      dataForm.append("file", file);
      setArquivoFile(dataForm);
      setNomeArquivo(file.name);
      setLoad(false);
    } catch (err) {
      setLoad(false);
      console.error("Erro ao fazer upload:", err);
    } finally {
    }
  }
  async function fnEnviarArquivo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiUpload(
        `upload/campanha`,
        arquivoFile
      );

      setRanking((prev) => ({
        ...prev,
        arquivoAnexado: response.data,
      }));
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnExtrair() {
    const parametros = {
      q: ranking.pesquisar || "",
      MarcaId: ranking.marcaId || "",
      GrupoId: ranking.grupoId || "",
      RoleId: ranking.roleId || "",
    };

    setLoad(true);
    try {
      const queryString = new URLSearchParams(parametros).toString();
      var url = "";
      if (ranking.roleId === "89B97424-23F8-455C-AD38-A9A2D9B1784B") {
        url = `ranking/gerente/exportar?${queryString}`;
      }
      if (ranking.roleId === "BD599436-282A-4B76-B419-4D767551DBD6") {
        url = `ranking/vendedor/exportar?${queryString}`;
      }
      let response = await ApiServices.apiDownloadXlsxGET(
        url,
        "Relatorio de ranking"
      );
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  useEffect(() => {
    fnListarMarca();
  }, [ranking.marcaId]);

  useEffect(() => {
    fnListarGrupoRanking();
  }, [ranking.marcaId]);
  useEffect(() => {
    if (ranking.marcaId === "") {
      setRanking((prev) => ({ ...prev, grupoId: "", roleId: "" }));
      setListaGrupoRanking([]);
      setListaCargo([]);
    }
  }, [ranking.marcaId]);

  useEffect(() => {
    if (ranking.marcaId && ranking.grupoId) {
      fnListarCargos();
    }
  }, [ranking.marcaId, ranking.grupoId]);

  useEffect(() => {
    if (ranking.grupoId === "") {
      setRanking((prev) => ({ ...prev, roleId: "" }));
      setListaCargo([]);
    }
  }, [ranking.grupoId]);

  useEffect(() => {
    if (ranking.marcaId && ranking.grupoId && ranking.roleId) {
      fnListarRanking();
    }
  }, [
    paginacao.pageSize,
    paginacao.pageIndex,
    ranking.marcaId,
    ranking.grupoId,
    ranking.roleId,
  ]);

  const codigoCor = [
    {
      nome: "Todos",
      codigoEnum: "",
    },
    {
      nome: "Centro-Oeste + Norte",
      codigoEnum: 1,
    },
    {
      nome: "Nordeste",
      codigoEnum: 2,
    },
    {
      nome: "Sudeste",
      codigoEnum: 3,
    },
    {
      nome: "Sul",
      codigoEnum: 4,
    },
    {
      nome: "São Paulo",
      codigoEnum: 5,
    },
  ];

  function getCorObjeto(codigoEnum) {
    switch (codigoEnum) {
      case 0:
        return codigoCor.find((cor) => cor.codigoEnum === 0);
      case 17:
        return codigoCor.find((cor) => cor.codigoEnum === 17);
      case 18:
        return codigoCor.find((cor) => cor.codigoEnum === 18);
      case 20:
        return codigoCor.find((cor) => cor.codigoEnum === 20);
      case 19:
        return codigoCor.find((cor) => cor.codigoEnum === 19);
      case 22:
        return codigoCor.find((cor) => cor.codigoEnum === 22);
      case 15:
        return codigoCor.find((cor) => cor.codigoEnum === 15);
      case 16:
        return codigoCor.find((cor) => cor.codigoEnum === 16);
      default:
        return null; // ou alguma outra lógica para códigos desconhecidos
    }
  }

  let itensOrdenados = listaGrupoRanking.sort(
    (a, b) => a.posicaoRanking - b.posicaoRanking
  );

  return (
    <>
      <div className="row">
        <span className="tituloPagina">RANKING</span>

        <div className="row">
          <div className="col-md-2 mt-3">
            <label>Região</label>
            <select
              className="inputPrimary"
              value={ranking.marcaId}
              onChange={(e) => {
                e.persist();
                setRanking((prev) => ({
                  ...prev,
                  marcaId: e.target.value,
                }));
              }}
            >
              {(codigoCor || []).map((e, i) => (
                <option key={i} value={e.codigoEnum}>
                  {e.nome}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2 mt-3">
            <label> </label>
            <button
              disabled={!ranking.marcaId || !ranking.grupoId || !ranking.roleId}
              className="btn btn-primary "
              onClick={fnListarRanking}
            >
              Buscar
            </button>
          </div>
          {/* <div className="col-md-2 mt-3">
            <label> </label>
            <button
              disabled={!ranking.marcaId || !ranking.grupoId || !ranking.roleId}
              className="btn btn-secondary "
              onClick={fnExtrair}
            >
              Extrair
            </button>
          </div> */}
        </div>

        <div className="row ">
          <div className="col-md-12 mt-3 ">
            {nomeArquivo && (
              <div className="d-flex align-items-center">
                <BsFiletypeXlsx style={{ fontSize: 30 }} />
                <div className="ms-2">{nomeArquivo}</div>
              </div>
            )}
          </div>
        </div>

        <span className="subTituloPagina mt-5">Participantes</span>
      </div>
      <Tabela
        colunas={colunasVendedor}
        dados={itensOrdenados || []}
        setPesquisar={(e) => {
          setRanking((prev) => ({
            ...prev,
            pesquisar: e,
          }));
        }}
        totalLinhas={listarRanking?.totalCount}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
      />
    </>
  );
}
