import React, { useEffect, useState } from "react";
import ApiServices from "../../services/apiServices";
import "./index.css";
import storage from "../../services/storage";
import { toast } from "react-toastify";
import { useLoad } from "../../context/Load";
import ForcaSenha from "../../Components/ForcaSenha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import Tabela from "../../Components/Tabela";
import { FaRegEdit } from "react-icons/fa";
import moment from "moment";

export default function GestaoDeCampanhas() {
  const [listaGestaoDeCampanhas, setGestaoDeCampanhas] = useState([]);

  const [filtroColuna, setFiltroColuna] = useState([]);
  const [novoFiltroColuna, setNovoFiltroColuna] = useState([]);

  const navigate = useNavigate();
  const { load, setLoad } = useLoad();
  const [paginacao, setPaginacao] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const colunas = [
    {
      accessorKey: "nome",
      header: "Nome",
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "dataInicio",
      header: "De",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "dataFim",
      header: "Até",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "tipo",
      header: "Tipo",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "ativo",
      header: "Status",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },

    {
      header: "Ações",
      size: 90,
      id: "mrt-custom-actions",
      accessor: (row) => row, // Pode ser ajustado conforme necessário
      Cell: ({ row }) => (
        <div
          className="pointer"
          onClick={() => {
            navigate(
              "/campanhas/gestao-de-campanhas/editar/" + row.original.campanhaId
            );
          }}
        >
          <FaRegEdit style={{ fontSize: 20, color:"#db3db1" }} />
        </div>
      ),
      muiTableHeadCellProps: { align: "right" },
      muiTableBodyCellProps: { align: "right" },
    },
  ];

  async function fnGestaoDeCampanhas() {
    const parametros = {
      nome: novoFiltroColuna.nome || "",
      userName: novoFiltroColuna.userName || "",
      email: novoFiltroColuna.email || "",
      status: novoFiltroColuna.ativo || "",
      tipo: novoFiltroColuna.tipo || "",
      dataDe: novoFiltroColuna.dataInicio || "",
      dataAte: novoFiltroColuna.dataFim || "",
    };

    try {
      setLoad(true);
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(`campanha?${queryString}`);

      if (response.statusCode == 200) {
        response.data.forEach((e) => {
          e.ativo = e.ativo == true ? "Ativo" : "Finalizada";
          e.dataFim = moment(e.dataFim).format("DD/MM/YYYY");
          e.dataInicio = moment(e.dataInicio).format("DD/MM/YYYY");
        });
        setGestaoDeCampanhas(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    const newObject = {};

    filtroColuna.forEach((item) => {
      newObject[item.id] = item.value;
    });
    setNovoFiltroColuna(newObject);
  }, [filtroColuna]);

  useEffect(() => {
    fnGestaoDeCampanhas();
  }, [novoFiltroColuna]);

  return (
    <>
      <div className="row">
        <span className="tituloPagina">CADASTRO DE CAMPANHAS</span>

        <div className="col-md-3">
          <button
            className="btn btn-primary my-2"
            onClick={() => {
              navigate("/campanhas/gestao-de-campanhas/novo");
            }}
          >
            + | Nova campanha
          </button>
        </div>
        <div className="subTituloPagina">Campanhas cadastradas</div>
      </div>
      <Tabela
        colunas={colunas}
        dados={listaGestaoDeCampanhas}
        setPaginacao={setPaginacao}
        paginacao={paginacao}
        filtroColuna={filtroColuna}
        setFiltroColuna={setFiltroColuna}
      />

      {/* <div style={{ width: 2500, height: 2500, backgroundColor: "green" }}>
        ffffffffffff
      </div> */}
    </>
  );
}
