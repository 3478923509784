import React from "react";
import Swiper from "swiper";
import "./index.css";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import storage from "../../services/storage";
import ApiServices from "../../services/apiServices";
import { useState } from "react";
import { toast } from "react-toastify";
import setaDireita from "../../assets/images/svg/setaDireita.svg";
import Logo from "../../assets/images/png/dev.png";
import { validaCpf } from "../../services/validaCpf";
import { validaEmail } from "../../services/validaEmail";
import Mask from "../../services/mask";
export default function Resetar() {
  const navigate = useNavigate();
  const [esqueciSenha, setEsqueciSenha] = useState({ userName: "" });

  async function fnResetar() {
    try {
      let response = await ApiServices.apiPost(
        "usuario/senha/resetar",
        esqueciSenha
      );

      if (response?.statusCode == 422) {
        toast.error(response.message);
        setEsqueciSenha((prev) => ({
          ...prev,
          userName: "",
        }));
      } else {
        toast.success("Reset realizado com sucesso. Verifique seu e-mail");
        navigate("/login");
      }
    } catch (err) {
      console.error("err", err);
    } finally {
    }
  }

  return (
    <>
      <div className="centroVertical">
        <div className="cardLogin d-flex fc">
          <div>
            <div className="textoLogin">
              <div>MAKE É</div>
              <div>MULTIPRÊMIOS</div>
            </div>
            <div className="textoLogin2">
              Celebra seu sucesso em vendas
              <br />
              com uma viagem internacional
            </div>
            <h5 className="textoLogin3">Esqueci minha senha</h5>

            <form autoComplete="off" className="w-100 ">
              <label>CPF</label>
              <input
                autoComplete="off"
                className="inputPrimary"
                type="text"
                value={Mask.cpf(esqueciSenha.userName)}
                onChange={(e) => {
                  setEsqueciSenha((prev) => ({
                    ...prev,
                    userName: Mask.number(e.target.value),
                  }));
                }}
              />
              <div className="avisoCampo">
                {!validaCpf(esqueciSenha.userName)
                  ? esqueciSenha?.userName?.length > 10
                    ? "Formato inválido"
                    : " "
                  : " "}
              </div>
            </form>
          </div>
          <div>
            <button
              className="btn btn-primary w-100 my-2"
              onClick={fnResetar}
              disabled={!validaCpf(esqueciSenha.userName)}
            >
              RESETAR SENHA
            </button>
            <button
              className="btn btn-secondary w-100 my-2"
              onClick={() => navigate("/")}
            >
              VOLTAR
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
