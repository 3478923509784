import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import { validaCnpj } from "../../services/validaCnpj";
import CloseIcon from "@mui/icons-material/Close";
export default function ConcessionariasNovoEditar() {
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();
  const [listaMarca, setListaMarca] = useState([]);
  const [listaDealerGrupo, setListaDealerGrupo] = useState([]);
  const [grupoRankingPorMarca, setGrupoRankingPorMarca] = useState({});
  const [listaGrupoMarca, setListaGrupoMarca] = useState([
    { marcaId: "", grupoRankingId: "" },
  ]);
  useEffect(() => {
    console.log("00000000000000dddddddddddddddddd00", grupoRankingPorMarca);
  }, [grupoRankingPorMarca]);
  const [concessionaria, setConcessionaria] = useState({
    codigoDealer: "",
    cnpj: "",
    nomeFantasia: "",
    status: "Ativo",
    dealerGrupoRankingId: "",
  });

  async function fnConcessionariaBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`dealer/` + params.id);

      setConcessionaria((prev) => ({
        ...prev,
        codigoDealer: response.data.codigoDealer,
        cnpj: response.data.cnpj,
        nomeFantasia: response.data.nomeFantasia,
        nomeFantasia: response.data.nomeFantasia,
        status: response.data.status,
        dealerGrupoRankingId: response.data.grupoId,
      }));

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnConcessionariaNovo() {
    try {
      var objEnvio = {
        codigoDealer: concessionaria.codigoDealer,
        cnpj: concessionaria.cnpj,
        nomeFantasia: concessionaria.nomeFantasia,
        status: concessionaria.status,
        grupoId: concessionaria.dealerGrupoRankingId,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`dealer`, objEnvio);

      if (response.statusCode == 200) {
        fnListagemNovo(response.data.dealerId);
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListagemBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(
        `dealer/grupo-ranking/` + params.id
      );
      console.log("response", response.data);

      if (response?.data?.length > 0) {
        var lista = [];
        response.data.map((e, i) => {
          lista.push({
            marcaId: e.marcaId,
            grupoRankingId: e.grupoRankingId,
          });
          fnListarGrupoRanking(e.marcaId);
        });
        setListaGrupoMarca(lista);
      } else {
        setListaGrupoMarca([{ marcaId: "", grupoRankingId: "" }]);
      }

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListagemNovo(dealerId) {
    try {
      var objEnvio = [];

      listaGrupoMarca.map((e, i) => {
        objEnvio.push({
          // dealerGrupoRankingId: concessionaria.dealerGrupoRankingId,
          dealerId: dealerId,
          marcaId: e.marcaId,
          grupoRankingId: e.grupoRankingId,
        });
      });

      setLoad(true);
      let response = await ApiServices.apiPost(
        `dealer/grupo-ranking`,
        objEnvio
      );

      if (response.statusCode == 200) {
        toast.success("Concessionária cadastrada com Sucesso");
        navigate("/gestao-de-usuarios/concessionarias");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnConcessionariaEditar() {
    try {
      var objEnvio = {
        codigoDealer: concessionaria.codigoDealer,
        cnpj: concessionaria.cnpj,
        nomeFantasia: concessionaria.nomeFantasia,
        status: concessionaria.status,
        grupoId: concessionaria.dealerGrupoRankingId,
      };

      setLoad(true);
      let response = await ApiServices.apiPut(`dealer/` + params.id, objEnvio);
      fnListagemNovo(response.data.dealerId);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarDealerGrupo() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("participante/grupo");

      setListaDealerGrupo(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarMarca() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet("participantes");

      setListaMarca(response.data);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnListarGrupoRanking(marcaId) {
    setLoad(true);
    const parametros = {
      // MarcaId: marcaId || "",
    };

    try {
      const queryString = new URLSearchParams(parametros).toString();
      let response = await ApiServices.apiGet(
        "ranking/grupo/todos?" + queryString
      );

      if (response.statusCode === 200) {
        setGrupoRankingPorMarca((prev) => ({
          ...prev,
          [marcaId]: response.data,
        }));
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {
    fnListarMarca();
    fnListarDealerGrupo();
  }, []);

  useEffect(() => {
    if (params.id) {
      fnConcessionariaBuscar();
      fnListagemBuscar();
    }
  }, []);
  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR CONCESSIONÁRIA" : "CADASTRO DE CONCESSIONÁRIA"}
      </div>
      <div className="subTituloPagina">Dados cadastrais</div>
      <div className="row">
        <div className="col-md-4 mt-3">
          <label>
            Código
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="inputPrimary"
              type="text"
              maxLength={8}
              value={concessionaria.codigoDealer}
              onChange={(e) => {
                e.persist();
                setConcessionaria((prev) => ({
                  ...prev,
                  codigoDealer: e.target.value.replace(/\D/g, ""),
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {concessionaria.codigoDealer.length < 1 ? "Inserir código" : " "}
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <label>
            CNPJ<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="inputPrimary"
              type="text"
              placeholder="00.000.000/0000-00"
              disabled={params.id}
              value={Mask.cnpj(concessionaria.cnpj)}
              onChange={(e) => {
                e.persist();
                setConcessionaria((prev) => ({
                  ...prev,
                  cnpj: Mask.number(e.target.value),
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!validaCnpj(concessionaria.cnpj) ? "Inserir CNPJ valido" : " "}
          </div>
        </div>
        <div className="col-md-4 mt-3">
          <label>
            Nome Fantasia
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="inputPrimary"
              type="text"
              value={concessionaria.nomeFantasia}
              onChange={(e) => {
                e.persist();
                setConcessionaria((prev) => ({
                  ...prev,
                  nomeFantasia: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {concessionaria.nomeFantasia.length < 3
              ? "Inserir nome fantasia"
              : " "}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-3">
          <label>
            Dealer Group
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="inputPrimary"
            value={concessionaria.dealerGrupoRankingId}
            onChange={(e) => {
              e.persist();
              setConcessionaria((prev) => ({
                ...prev,
                dealerGrupoRankingId: e.target.value,
              }));
            }}
          >
            <option value="">Selecione</option>
            {(listaDealerGrupo || []).map((e, i) => (
              <option key={i} value={e.participanteGrupoId}>
                {e.nome}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="inputPrimary"
            value={concessionaria.status}
            onChange={(e) => {
              e.persist();
              setConcessionaria((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value={"Ativo"}>Ativo</option>
            <option value={"Inativo"}>Inativo</option>
          </select>
        </div>
      </div>
      <div className="subTituloPagina"> BMW Group & Ranking</div>

      {(listaGrupoMarca || []).map((ele, i) => (
        <div className="row" key={i}>
          <div className="col-md-5">
            <label style={{ color: "#000000" }} className="mt-3">
              Marca<span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <select
              className="inputPrimary"
              value={ele.marcaId}
              onChange={async (e) => {
                const newListaGrupoMarca = [...listaGrupoMarca];
                newListaGrupoMarca[i].marcaId = e.target.value;
                setListaGrupoMarca(newListaGrupoMarca);

                // Atualize a lista de grupo ranking para a marca selecionada
                await fnListarGrupoRanking(e.target.value);
              }}
            >
              <option value="">Selecione</option>
              {(listaMarca || []).map((e, idx) => (
                <option key={idx} value={e.marcaId}>
                  {e.descricao}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-5">
            <label style={{ color: "#000000" }} className="mt-3">
              Grupo Ranking
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>

            <select
              className="inputPrimary"
              value={ele.grupoRankingId}
              onChange={(e) => {
                const newListaGrupoMarca = [...listaGrupoMarca];
                newListaGrupoMarca[i].grupoRankingId = e.target.value;
                setListaGrupoMarca(newListaGrupoMarca);
              }}
            >
              <option value="">Selecione</option>
              {(grupoRankingPorMarca[ele.marcaId] || []).map((e, idx) => (
                <option key={idx} value={e.grupoRankingId}>
                  {e.descricao}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2 d-flex flex-column justify-content-end">
            <label> </label>
            <button
              className="btn btn-danger"
              onClick={() => {
                setListaGrupoMarca((prev) =>
                  prev.filter((_, index) => index !== i)
                );
              }}
            >
              REMOVER
            </button>
          </div>
        </div>
      ))}

      <div className="row mt-5 d-flex flex-column align-items-center justify-content-center mb-5">
        <div className="col-md-6">
          <button
            className="btn btn-primary"
            onClick={() => {
              setListaGrupoMarca((prev) => [
                ...prev,
                { marcaId: "", grupoRankingId: "" },
              ]);
            }}
          >
            ADICIONAR E NOVO
          </button>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-3 mt-2">
            <button
              className="btn btn-secondary w-100 "
              onClick={() => {
                navigate(-1);
              }}
            >
              VOLTAR
            </button>
          </div>
          <div className="col-md-3 mt-2">
            <button
              className="btn btn-primary w-100 "
              onClick={
                params.id ? fnConcessionariaEditar : fnConcessionariaNovo
              }
              disabled={
                concessionaria.codigoDealer.length < 1 ||
                !validaCnpj(concessionaria.cnpj) ||
                concessionaria.nomeFantasia.length < 3
              }
            >
              {params.id ? "ALTERAR" : "SALVAR"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
