import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import "./index.css";
import ApiServices from "../../services/apiServices";
import { useLoad } from "../../context/Load";
import storage from "../../services/storage";
import ForcaSenha from "../../Components/ForcaSenha";
import Mask from "../../services/mask";
import moment from "moment";

export default function GestaoDeCampanhasNovoEditar() {
  const { load, setLoad } = useLoad();
  const navigate = useNavigate();
  const params = useParams();
  const [verImagem, setVerImagem] = useState("");
  const [arquivoFile, setArquivoFile] = useState(null);
  const [linhaRegra, setLinhaRegra] = useState([]);
  const [modeloRegra, setModeloRegra] = useState([]);
  const [perfilRegra, setPerfilRegra] = useState([]);
  const [grupoRegra, setGrupoRegra] = useState([]);
  const [validaLista, setValidaLista] = useState(false);
  const [tipo, setTipo] = useState([]);
  const [regrasItem, setRegrasItem] = useState([
    {
      veiculoLinhas: [],
      veiculoModelos: [],
      unidadeVendidaDe: "",
      unidadeVendidaAte: "",
      participanteCargo: {
        participanteCargoId: "",
        nome: "",
      },
      participanteGrupo: {
        participanteGrupoId: "",
        nome: "",
      },
      pontosPorUnidade: "",
    },
  ]);
  const [gestaoDeCampanhas, setGestaoDeCampanhas] = useState({
    nome: "",
    dataInicio: "",
    dataFim: "",

    dataInicioVarejo: "",
    dataFimVarejo: "",
    regras: [],

    tipo: "",
    status: "ativo",
    descricao: "",
    comoFunciona: "",
    imagemAnexada: "",
  });

  function fnbAddNovaRegra() {
    setRegrasItem((prev) => [
      ...prev,
      {
        veiculoLinhas: [],
        veiculoModelos: [],
        unidadeVendidaDe: "",
        unidadeVendidaAte: "",
        participanteCargo: {
          participanteCargoId: "",
          nome: "",
        },
        participanteGrupo: {
          participanteGrupoId: "",
          nome: "",
        },
        pontosPorUnidade: "",
      },
    ]);
  }
  async function fnPerfilRegra() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`participante/cargo`);

      setPerfilRegra(response.data);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnGrupoRegra() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`participante/grupo`);

      setGrupoRegra(response.data);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnLinhaRegra() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`veiculo/linha`);

      setLinhaRegra(response.data);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnModeloRegra() {
    try {
      setLoad(true);

      const promises = regrasItem.map(async (e) => {
        if (e.veiculoLinhas.length) {
          var lista = [];

          e.veiculoLinhas.map(async (ee) => {
            lista.push(`veiculoLinhaId=` + ee.veiculoLinhaId + "&");
          });

          const response = await ApiServices.apiGet(
            `veiculo/modelo?` + lista.join("").replace(/&$/, "")
          );
          return response.data;
        } else {
          return [];
        }
      });

      const responses = await Promise.all(promises);
      const filteredResponses = responses.filter(
        (response) => response !== undefined
      );

      setModeloRegra(filteredResponses);
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnTiposCampanha() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`campanha/tipos`);
      setTipo(response.data);
      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnGestaoDeCampanhasBuscar() {
    try {
      setLoad(true);
      let response = await ApiServices.apiGet(`campanha/` + params.id);
      setGestaoDeCampanhas((prev) => ({
        ...prev,
        nome: response.data?.nome,
        dataInicio: moment(response.data?.dataInicio).format("YYYY-MM-DD"),
        dataFim: moment(response.data?.dataFim).format("YYYY-MM-DD"),
        tipo: response.data.tipo.campanhaTipoId,
        status: response.data.ativo ? "ativo" : "inativo",
        descricao: response.data?.descricao,
        comoFunciona: response.data?.comoFunciona,
        dataInicioVarejo: moment(response.data?.dataVarejoDe).format(
          "YYYY-MM-DD"
        ),
        dataFimVarejo: moment(response.data?.dataVarejoAte).format(
          "YYYY-MM-DD"
        ),
      }));

      setRegrasItem(response.data?.regras);

      setVerImagem(response.data?.urlImagem);

      if (response.statusCode == 200) {
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnGestaoDeCampanhasNovo() {
    try {
      var objEnvio = {
        nome: gestaoDeCampanhas.nome,
        dataInicio: gestaoDeCampanhas.dataInicio,
        dataFim: gestaoDeCampanhas.dataFim,
        tipo: tipo.find((e) => {
          if (e.campanhaTipoId === gestaoDeCampanhas.tipo) {
            return e;
          }
        }),
        ativo: gestaoDeCampanhas.status === "ativo" ? true : false,
        descricao: gestaoDeCampanhas.descricao,
        comoFunciona: gestaoDeCampanhas.comoFunciona,
        urlImagem: gestaoDeCampanhas.imagemAnexada,
        dataVarejoDe: gestaoDeCampanhas.dataInicioVarejo,
        dataVarejoAte: gestaoDeCampanhas.dataFimVarejo,
        regras: regrasItem,
      };

      setLoad(true);
      let response = await ApiServices.apiPost(`campanha`, objEnvio);

      if (response.statusCode == 200) {
        toast.success("Campanha cadastrada");
        navigate("/campanhas/gestao-de-campanhas");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnGestaoDeCampanhasEditar() {
    try {
      var objEnvio = {
        nome: gestaoDeCampanhas.nome,
        dataInicio: gestaoDeCampanhas.dataInicio,
        dataFim: gestaoDeCampanhas.dataFim,
        tipo: tipo.find((e) => {
          if (e.campanhaTipoId === gestaoDeCampanhas.tipo) {
            return e;
          }
        }),
        ativo: gestaoDeCampanhas.status === "ativo" ? true : false,
        descricao: gestaoDeCampanhas.descricao,
        comoFunciona: gestaoDeCampanhas.comoFunciona,
        urlImagem: gestaoDeCampanhas.imagemAnexada || verImagem,
        dataVarejoDe: gestaoDeCampanhas.dataInicioVarejo,
        dataVarejoAte: gestaoDeCampanhas.dataFimVarejo,
        regras: regrasItem,
      };

      setLoad(true);
      let response = await ApiServices.apiPut(
        `campanha/` + params.id,
        objEnvio
      );

      if (response.statusCode == 200) {
        toast.success("Campanha alterada");
        navigate("/campanhas/gestao-de-campanhas");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  async function fnCarregarImagem(e) {
    try {
      const file = e.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onload = function (e) {
          const image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            if (image.width !== 360 && image.height !== 360) {
              toast.warn("Imagem deve ter 360x360px");
            } else {
              setVerImagem(e.target.result);
              setLoad(false);
              const dataForm = new FormData();
              dataForm.append("file", file);
              setArquivoFile(dataForm);
            }
          };
        };

        reader.readAsDataURL(file);
      } else {
      }
    } catch (err) {
      console.error("Erro ao fazer upload:", err);
    } finally {
      // Algumas ações que você queira executar, se necessário
    }
  }
  async function fnEnviarImagemApi() {
    try {
      setLoad(true);
      let response = await ApiServices.apiUpload(
        `upload/campanha`,
        arquivoFile
      );

      setGestaoDeCampanhas((prev) => ({
        ...prev,
        imagemAnexada: response.data,
      }));
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoad(false);
    }
  }
  function validarLista(lista) {
    for (let i = 0; i < lista.length; i++) {
      const item = lista[i];
      if (
        !item.veiculoLinhas.length > 0 ||
        !item.veiculoModelos.length > 0 ||
        !item.unidadeVendidaDe ||
        !item.unidadeVendidaAte ||
        !item.participanteCargo.participanteCargoId ||
        !item.participanteCargo.nome ||
        !item.participanteGrupo.participanteGrupoId ||
        !item.participanteGrupo.nome ||
        !item.pontosPorUnidade
      ) {
        setValidaLista(false);
        return;
      }
    }

    setValidaLista(true);
    return;
  }
  useEffect(() => {
    if (gestaoDeCampanhas.imagemAnexada != "") {
      params.id ? fnGestaoDeCampanhasEditar() : fnGestaoDeCampanhasNovo();
    }
  }, [gestaoDeCampanhas.imagemAnexada]);

  useEffect(() => {
    if (params.id) {
      fnGestaoDeCampanhasBuscar();
    }
    fnTiposCampanha();
    fnLinhaRegra();
    fnPerfilRegra();
    fnGrupoRegra();
  }, []);

  useEffect(() => {
    fnModeloRegra();
    validarLista(regrasItem);
  }, [regrasItem]);

  return (
    <div className="row">
      <div className="tituloPagina">
        {params.id ? "EDITAR CAMPANHA" : "CADASTRO DE CAMPANHA"}
      </div>
      <div className="subTituloPagina">Configuração geral</div>
      <div className="row">
        <div className="col-md-4 mt-3">
          <label>
            Nome<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="inputPrimary"
              type="text"
              value={gestaoDeCampanhas.nome}
              onChange={(e) => {
                e.persist();
                setGestaoDeCampanhas((prev) => ({
                  ...prev,
                  nome: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {gestaoDeCampanhas.nome.length < 3 ? "Inserir nome" : " "}
          </div>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Início<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="inputPrimary"
              type="date"
              value={gestaoDeCampanhas.dataInicio}
              onChange={(e) => {
                e.persist();
                setGestaoDeCampanhas((prev) => ({
                  ...prev,
                  dataInicio: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!gestaoDeCampanhas.dataInicio ? "Inserir data" : " "}
          </div>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Término<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="inputPrimary"
              type="date"
              value={gestaoDeCampanhas.dataFim}
              onChange={(e) => {
                e.persist();
                setGestaoDeCampanhas((prev) => ({
                  ...prev,
                  dataFim: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!gestaoDeCampanhas.dataFim ? "Inserir data" : " "}
          </div>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Tipo<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="inputPrimary"
            value={gestaoDeCampanhas.tipo}
            onChange={(e) => {
              e.persist();

              setGestaoDeCampanhas((prev) => ({
                ...prev,
                tipo: e.target.value,
              }));
            }}
          >
            <option value="">Selecione</option>
            {tipo.map((e, i) => (
              <option value={e.campanhaTipoId} key={i}>
                {e.nome}
              </option>
            ))}
          </select>
          <div className="avisoCampo">
            {!gestaoDeCampanhas.tipo ? "Inserir tipo" : " "}
          </div>
        </div>
        <div className="col-md-2 mt-3">
          <label>
            Status<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>

          <select
            className="inputPrimary"
            value={gestaoDeCampanhas.status}
            onChange={(e) => {
              e.persist();
              setGestaoDeCampanhas((prev) => ({
                ...prev,
                status: e.target.value,
              }));
            }}
          >
            <option value="ativo">Ativo</option>
            <option value="inativo">Inativo</option>
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-3">
          <label>
            Descrição<span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="inputPrimary"
              type="text"
              value={gestaoDeCampanhas.descricao}
              onChange={(e) => {
                e.persist();
                setGestaoDeCampanhas((prev) => ({
                  ...prev,
                  descricao: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {gestaoDeCampanhas.descricao.length < 3 ? "Inserir descrição" : " "}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-3">
          <label>
            Como funciona
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <textarea
              autoComplete="off"
              className="inputPrimary"
              rows={5}
              placeholder="Descreva aqui a mecânica da campanha"
              type="text"
              value={gestaoDeCampanhas.comoFunciona}
              onChange={(e) => {
                e.persist();
                setGestaoDeCampanhas((prev) => ({
                  ...prev,
                  comoFunciona: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {gestaoDeCampanhas.comoFunciona.length < 3
              ? "Inserir como funciona"
              : " "}
          </div>
        </div>
      </div>
      <div>
        {verImagem ? (
          <button
            className="btn btn-danger my-3"
            style={{ width: 360 }}
            onClick={() => {
              setVerImagem("");
              setArquivoFile(null);
              document.querySelector(".inputUpload").value = "";
            }}
          >
            Excluir arte da campanha
          </button>
        ) : (
          <button
            className="btn btn-primary my-3"
            style={{ width: 360 }}
            onClick={() => {
              document.querySelector(".inputUpload").click();
            }}
          >
            Importar arte da campanha
          </button>
        )}
        <input
          className="d-none inputUpload"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={fnCarregarImagem}
        />
        <div style={{ fontSize: 12 }}>Formatos aceitos: jpeg, jpg, png</div>
        <div style={{ fontSize: 12 }}>Tamanho recomendado: 360 x 360 px</div>
        <div
          className="border"
          style={{ width: 360, height: 360, overflow: "hidden" }}
        >
          <img src={verImagem} alt="" />
        </div>
      </div>
      <div className="subTituloPagina">Configuração do motor</div>
      <div className="row">
        {/* DATA VAREJO DE */}
        <div className="col-md-2 mt-3">
          <label>
            Data varejo DE
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="inputPrimary"
              type="date"
              value={gestaoDeCampanhas.dataInicioVarejo}
              onChange={(e) => {
                e.persist();
                setGestaoDeCampanhas((prev) => ({
                  ...prev,
                  dataInicioVarejo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!gestaoDeCampanhas.dataInicioVarejo ? "Inserir data" : " "}
          </div>
        </div>
        {/* DATA VAREJO ATÉ */}
        <div className="col-md-2 mt-3">
          <label>
            Data varejo ATÉ
            <span style={{ color: "red", fontWeight: 900 }}>*</span>
          </label>
          <form autoComplete="off">
            <input
              autoComplete="off"
              className="inputPrimary"
              type="date"
              value={gestaoDeCampanhas.dataFimVarejo}
              onChange={(e) => {
                e.persist();
                setGestaoDeCampanhas((prev) => ({
                  ...prev,
                  dataFimVarejo: e.target.value,
                }));
              }}
            />
          </form>
          <div className="avisoCampo">
            {!gestaoDeCampanhas.dataFimVarejo ? "Inserir data" : " "}
          </div>
        </div>
      </div>
      <div className="subTituloPagina">Regra de pontuação</div>
      {regrasItem.map((eMap, i) => (
        <div className="row" key={i}>
          {/* LINHA */}
          <div className="col-md-1 mt-3">
            <div>
              <label>
                Linha
                <span style={{ color: "red", fontWeight: 900 }}>*</span>
              </label>
              <button
                className=" form-control d-flex justify-content-between w-100 "
                data-bs-toggle="dropdown"
              >
                <div className=" tresPontinhos d-flex" style={{ width: "80%" }}>
                  {eMap.veiculoLinhas.length}
                   Selecionados
                </div>
                <div>
                  <svg
                    fill="#000000"
                    height="10px"
                    width="10px"
                    viewBox="0 0 330 330"
                  >
                    <path
                      id="XMLID_225_"
                      d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                      stroke="#000000"
                      strokeWidth="10"
                    />
                  </svg>
                </div>
              </button>
              <div
                className="dropdown-menu"
                style={{ maxHeight: 300, overflowY: "auto" }}
              >
                {linhaRegra?.map((option, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center"
                    onClick={(eClick) => {
                      eClick.stopPropagation();
                      var x = eMap.veiculoLinhas;
                      var existeIndex = x.findIndex(
                        (item) => item.veiculoLinhaId === option.veiculoLinhaId
                      );
                      if (existeIndex !== -1) {
                        x.splice(existeIndex, 1);
                      } else {
                        x.push(option);
                      }
                      setRegrasItem((prev) => {
                        return prev.map((item, index) =>
                          index === i
                            ? {
                                ...item,
                                veiculoLinhas: x,
                                veiculoModelos: [],
                              }
                            : item
                        );
                      });
                    }}
                  >
                    <div className="m-1 ms-3  ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={eMap.veiculoLinhas.some(
                          (item) =>
                            item.veiculoLinhaId === option.veiculoLinhaId
                        )}
                        onChange={() => {}}
                      />
                    </div>
                    <label className="m-1 fs-6">
                      {option.nome}
                      <span style={{ color: "red", fontWeight: 900 }}>*</span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="avisoCampo">
              {!eMap.veiculoLinhas.length > 0 ? "Selecione uma linha" : " "}
            </div>
          </div>
          {/* MODELO */}
          <div className="col-md-2 mt-3">
            <div>
              <label>
                Modelo / Versão
                <span style={{ color: "red", fontWeight: 900 }}>*</span>
              </label>
              <button
                className=" form-control d-flex justify-content-between w-100 "
                data-bs-toggle="dropdown"
              >
                <div className=" tresPontinhos d-flex" style={{ width: "80%" }}>
                  {eMap.veiculoModelos.length}
                   Selecionados
                </div>
                <div>
                  <svg
                    fill="#000000"
                    height="10px"
                    width="10px"
                    viewBox="0 0 330 330"
                  >
                    <path
                      id="XMLID_225_"
                      d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                      stroke="#000000"
                      strokeWidth="10"
                    />
                  </svg>
                </div>
              </button>
              <div
                className="dropdown-menu"
                style={{ maxHeight: 300, overflowY: "auto" }}
              >
                {Array.isArray(modeloRegra[i]) &&
                  modeloRegra[i]?.map((option, index) => (
                    <div
                      key={index}
                      className="d-flex align-items-center"
                      onClick={(eClick) => {
                        eClick.stopPropagation();
                        var x = eMap.veiculoModelos;
                        var existeIndex = x.findIndex(
                          (item) =>
                            item.veiculoModeloId === option.veiculoModeloId
                        );
                        if (existeIndex !== -1) {
                          x.splice(existeIndex, 1);
                        } else {
                          x.push(option);
                        }
                        setRegrasItem((prev) => {
                          return prev.map((item, index) =>
                            index === i
                              ? {
                                  ...item,
                                  veiculoModelos: x,
                                }
                              : item
                          );
                        });
                      }}
                    >
                      <div className="m-1 ms-3  ">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={eMap.veiculoModelos.some(
                            (item) =>
                              item.veiculoModeloId === option.veiculoModeloId
                          )}
                          onChange={() => {}}
                        />
                      </div>
                      <label className="m-1 fs-6">
                        {option.nome}
                        <span style={{ color: "red", fontWeight: 900 }}>*</span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            <div className="avisoCampo">
              {!eMap.veiculoModelos.length > 0
                ? "Selecione um Modelo / versão"
                : " "}
            </div>
          </div>
          {/* VENDIDA DE */}
          <div className="col-md-1 mt-3">
            <label>
              Unidade vendida DE
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                className="inputPrimary"
                type="text"
                value={eMap.unidadeVendidaDe}
                onChange={(eChange) => {
                  setRegrasItem((prev) => {
                    return prev.map((item, index) =>
                      index === i
                        ? {
                            ...item,
                            unidadeVendidaDe: Number(
                              Mask.number(eChange.target.value)
                            ),
                          }
                        : item
                    );
                  });
                }}
              />
            </form>
            <div className="avisoCampo">
              {!regrasItem[i].unidadeVendidaDe ? "Inserir unidade" : " "}
            </div>
          </div>
          {/* VENDIDA ATÉ */}
          <div className="col-md-1 mt-3">
            <label>
              Unidade vendida ATÉ
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                className="inputPrimary"
                type="text"
                value={eMap.unidadeVendidaAte}
                onChange={(eChange) => {
                  setRegrasItem((prev) => {
                    return prev.map((item, index) =>
                      index === i
                        ? {
                            ...item,
                            unidadeVendidaAte: Number(
                              Mask.number(eChange.target.value)
                            ),
                          }
                        : item
                    );
                  });
                }}
              />
            </form>
            <div className="avisoCampo">
              {!regrasItem[i].unidadeVendidaAte ? "Inserir unidade" : " "}
            </div>
          </div>
          {/* PERFIL */}
          <div className="col-md-2 mt-3">
            <label>
              Perfil participante
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>

            <select
              className="inputPrimary"
              value={eMap.participanteCargo.participanteCargoId}
              onChange={(eChange) => {
                setRegrasItem((prev) => {
                  return prev.map((item, index) =>
                    index === i
                      ? {
                          ...item,
                          participanteCargo: {
                            ...item.participanteCargo,
                            participanteCargoId: eChange.target.value,
                            nome:
                              perfilRegra.find(
                                (ev) =>
                                  ev.participanteCargoId ===
                                  eChange.target.value
                              )?.nome || "",
                          },
                        }
                      : item
                  );
                });
              }}
            >
              <option value="">Selecione</option>
              {perfilRegra.map((e, i) => (
                <option value={e.participanteCargoId} key={i}>
                  {e.nome}
                </option>
              ))}
            </select>
            <div className="avisoCampo">
              {!regrasItem[i].participanteCargo.participanteCargoId
                ? "Inserir perfil"
                : " "}
            </div>
          </div>
          {/* GRUPO */}
          <div className="col-md-2 mt-3">
            <label>
              Grupos participantes
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>

            <select
              className="inputPrimary"
              value={eMap.participanteGrupo.participanteGrupoId}
              onChange={(eChange) => {
                setRegrasItem((prev) => {
                  return prev.map((item, index) =>
                    index === i
                      ? {
                          ...item,
                          participanteGrupo: {
                            ...item.participanteGrupo,
                            participanteGrupoId: eChange.target.value,
                            nome:
                              grupoRegra.find(
                                (ev) =>
                                  ev.participanteGrupoId ===
                                  eChange.target.value
                              )?.nome || "",
                          },
                        }
                      : item
                  );
                });
              }}
            >
              <option value="">Selecione</option>
              {grupoRegra.map((e, i) => (
                <option value={e.participanteGrupoId} key={i}>
                  {e.nome}
                </option>
              ))}
            </select>
            <div className="avisoCampo">
              {!regrasItem[i].participanteGrupo.participanteGrupoId
                ? "Inserir grupo"
                : " "}
            </div>
          </div>
          {/* PONTOS POR UNIDADE */}
          <div className="col-md-1 mt-3">
            <label>
              Pontos por unidade
              <span style={{ color: "red", fontWeight: 900 }}>*</span>
            </label>
            <form autoComplete="off">
              <input
                autoComplete="off"
                className="inputPrimary"
                type="text"
                value={eMap.pontosPorUnidade}
                onChange={(eChange) => {
                  setRegrasItem((prev) => {
                    return prev.map((item, index) =>
                      index === i
                        ? {
                            ...item,
                            pontosPorUnidade: Number(
                              Mask.number(eChange.target.value)
                            ),
                          }
                        : item
                    );
                  });
                }}
              />
            </form>
            <div className="avisoCampo">
              {!regrasItem[i].pontosPorUnidade ? "Inserir unidade" : " "}
            </div>
          </div>
          {/* BOTÃO ADD */}
          {regrasItem.length === i + 1 && (
            <div className="col-md-2 mt-3">
              <label> </label>
              <button className="btn btn-primary" onClick={fnbAddNovaRegra}>
                Adicionar nova regra
              </button>
            </div>
          )}
        </div>
      ))}

      {/* /////////////////////////////////////////////////////////////////////////////// */}
      {/* ///////////////////////////   VOLTAR | SALVAR   /////////////////////////////// */}
      {/* /////////////////////////////////////////////////////////////////////////////// */}
      <div className="row mt-5 d-flex justify-content-center mb-5">
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-secondary w-100 "
            onClick={() => {
              navigate(-1);
            }}
          >
            VOLTAR
          </button>
        </div>
        <div className="col-md-3 mt-2">
          <button
            className="btn btn-primary w-100 "
            onClick={fnEnviarImagemApi}
            disabled={
              gestaoDeCampanhas.nome.length < 3 ||
              !gestaoDeCampanhas.dataInicio ||
              !gestaoDeCampanhas.dataFim ||
              !gestaoDeCampanhas.tipo ||
              gestaoDeCampanhas.descricao.length < 3 ||
              gestaoDeCampanhas.comoFunciona.length < 3 ||
              !verImagem ||
              !gestaoDeCampanhas.dataInicioVarejo ||
              !gestaoDeCampanhas.dataFimVarejo ||
              !validaLista
            }
          >
            {params.id ? "ALTERAR" : "SALVAR"}
          </button>
        </div>
      </div>
    </div>
  );
}
