import React, { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { Box } from "@mui/material";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import { IconButton } from "@mui/material";
import { MRT_Localization_PT } from "material-react-table/locales/pt";

export default function Tabela({
  setPaginacao,
  paginacao,
  colunas,
  dados,
  setPesquisar,
  totalLinhas,
  filtroColuna,
  setFiltroColuna,
  paginacaoPelaApi = false,
  buscaGlobal = false,
  buscaColunas = true,
}) {
  return (
    <MaterialReactTable
      // enablePagination={false}
      columns={colunas}
      data={dados}
      enableColumnResizing={true}
      enableDensityToggle={false}
      initialState={{ density: "compact" }}
      localization={MRT_Localization_PT}
      onGlobalFilterChange={setPesquisar}
      enableGlobalFilter={buscaGlobal} // PESQUISA GLOBAL
      manualFiltering={true}
      enableColumnFilters={buscaColunas}
      onColumnFiltersChange={setFiltroColuna}
      columnResizeMode={"onEnd"}
      rowCount={totalLinhas}
      manualPagination={paginacaoPelaApi}
      onPaginationChange={setPaginacao}
      state={{ pagination: paginacao, columnFilters: filtroColuna }}
      muiTablePaginationProps={{
        rowsPerPageOptions: [5, 10, 50, 100],
        showFirstButton: true,
        showLastButton: true,
      }}
    />
  );
}
