import React, { useEffect, useState } from "react";
import "./index.css";
import logo from "../../assets/images/png/bgEscuro.png";
export default function Home() {
  return (
    <>
      <div
        className="bgHome"
        style={{
          backgroundImage: `url(${logo})`,
          backgroundPosition:"right bottom"
        }}
      >
        {/* Conteúdo dentro do div */}
      </div>
    </>
  );
}
